import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import water_tanker from "../../public/static/images/products/water_tanker.jpg"

function WaterTanker() {

  const prodDetail = {
    "name": "WATER TANKS",
    "detail": {
      "about" : ["We are manufacturer & supplier of premium quality SS / MS Water Storage Tank. Latest production techniques, use of excellent raw material combined with innovative styling & designing has made this product reach the pinnacle where it is today. Our 500 upto 10000 Liters SS and MS Water Storage Tank comes with a modern design making it efficient and maintenance free for a long time."],
      "advantages": [
        "Non Toxic",
        "No Smell",
        "Easy to Clean",
        "Prevents growth of Allergy & Fungus",
        "100% Hygienic",
        "International Technology",
        "No Rust",
        "Elegant in look",
        "Normal water in winter",
        "Works of year & years",
        "Cold water in summer"
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Body Size",
        "Sheet Material",
        "Thickness",
        "Valuematic Capacity",
        "Axle & Wheels",
        "Water Tab",
        "Drain Valve",
        "Driver Type"
      ],
      "column2": [
        "IEWT",
        "Tractor Trailer mounted or Customized",
        "Elliptical Shape etc.",
        "Mild Steel Sheets IS 2062 Grade - A / Stainless Steel etc.",
        "2.5 mm, 3 mm, 5 mm or can be Customized as per requirement.",
        "500 upto 10000 or can be Customized",
        "Heavy Duty Rigid Axle with 1 Nos. of 2 nos.",
        "04 nos",
        "01 nos",
        "Tractor Chassis"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={water_tanker} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default WaterTanker
